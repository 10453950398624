import * as React from "react";
import styled from "styled-components";
import Title from "../components/Title";
import SEO from "../components/SEO";

function BookOnline({ pageContext }) {
  const { firstName, lastName, booking, slug } = pageContext;
  return (
    <BookContent>
      <SEO
        title={`CLIPS Hair Studio | Book online with ${firstName} ${lastName}`}
        description={`${firstName} ${lastName} is accepting appointments online.`}
        image={pageContext.image}
        url={`https://www.clips-hairstudio.com/book-online/${slug}/`}
      />
      <Title>
        Book Online with {firstName} {lastName}
      </Title>
      <iframe
        src={booking}
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
      ></iframe>
    </BookContent>
  );
}

export default BookOnline;

const BookContent = styled.div`
  position: relative;
  top: var(--nav-height);
`;
